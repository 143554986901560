import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import { css } from 'emotion'
import { get } from 'lodash'
import Layout from '../components/Layout'
import { Layout as _Layout, Text, Color } from '../constants'

export const ThanksPageTemplate = ({ heading, details }) => (
  <div>
    <div className={css(styles.container)}>
      <Link to="/">
        <h1 className={css(styles.heading)}>{heading}</h1>
        <div className={css(styles.bodyContainer)}>
          <p className={css(styles.details)}>{details}</p>
        </div>
      </Link>
    </div>
  </div>
)

const ThanksPage = ({ data }) => {
  const {
    markdownRemark: { frontmatter },
    navbarData,
    footerData,
  } = data

  return (
    <Layout
      footerData={footerData}
      navbarData={navbarData}
      color={Color.DEFAULT}
    >
      <ThanksPageTemplate
        heading={frontmatter.heading}
        details={frontmatter.details}
      />
    </Layout>
  )
}

export default ThanksPage

export const thanksPageQuery = graphql`
  query ThanksPage($id: String!) {
    ...LayoutFragment
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        details
      }
    }
  }
`

const styles = {
  container: {
    textAlign: 'center',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: Color.DEFAULT,
    height: '100%',
    height: '-moz-available' /* WebKit-based browsers will ignore this. */,
    height:
      '-webkit-fill-available' /* Mozilla-based browsers will ignore this. */,
    height: 'fill-available',
  },
  heading: {
    ...Text.MAIN_TITLE,
    color: Color.WHITE,
  },
  bodyContainer: {
    maxWidth: 600,
    display: 'flex',
  },
  details: {
    paddingRight: _Layout.EDGE_PADDING,
    paddingLeft: _Layout.EDGE_PADDING,
    color: Color.WHITE,
    ...Text.BODY,
  },
}
